
const __sfc_main = {};
__sfc_main.props = {
  loading: {
    key: "loading",
    required: false,
    type: Boolean,
    default: false
  },
  active: {
    key: "active",
    required: false,
    type: Boolean,
    default: false
  },
  small: {
    key: "small",
    required: false,
    type: Boolean,
    default: false
  },
  theme: {
    key: "theme",
    required: false,
    type: String,
    default: 'black'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  function handleClickCart() {
    props.active ? emit('remove') : emit('add');
  }
  return {
    handleClickCart
  };
};
export default __sfc_main;
