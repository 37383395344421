
import type { PropType } from '@vue/composition-api'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GGLink',
  props: {
    to: {
      type: String as PropType<string>,
      default: '#',
    },
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  computed: {
    tag() {
      return this.$props.to?.includes('http') ? 'a' : 'nuxt-link'
    },
  },
})
