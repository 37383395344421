import { render, staticRenderFns } from "./GGLink.vue?vue&type=template&id=70fc6f81&scoped=true&"
import script from "./GGLink.vue?vue&type=script&lang=ts&"
export * from "./GGLink.vue?vue&type=script&lang=ts&"
import style0 from "./GGLink.vue?vue&type=style&index=0&id=70fc6f81&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fc6f81",
  null
  
)

export default component.exports