var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tag === 'nuxt-link' ? _c('nuxt-link', {
    staticClass: "gg-link",
    attrs: {
      "to": _vm.to,
      "title": _vm.title
    }
  }, [_vm._t("default")], 2) : _c('a', {
    staticClass: "gg-link",
    attrs: {
      "href": _vm.to,
      "title": _vm.title || undefined
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }