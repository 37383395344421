
import { mapActions, mapState } from 'pinia'
import { defineComponent, inject } from '@vue/composition-api'
import { GGScrollController, GGSectionList } from '@constructor/component'
import { Modal } from '05-ui-kit'
import getPageDataByCityName from '~/graphql/PageDataByCityName.gql'
import ErrorSerializer from '~/serializer/Error'
import getOpenGraphMarkup from '~/helper/getOpenGraphMarkup'
import { useAuthStore } from '~/store/modal/auth'
import { useLocationStore } from '~/store/location'
import StoriesSlide from '~/components/sliders/StoriesSlide/StoriesSlide.vue'
import { getStatusPromo } from '~/helper/getStatusPromo'
import MainPromoBanners from '~/components/nam20/MainPromoBanners/index.vue'

export default defineComponent({
  name: 'IndexPage',
  components: {
    GGSectionList,
    GGScrollController,
    StoriesSlide,
    MainPromoBanners,
    Modal,
  },
  transition: 'fade',
  setup() {
    const emitter = inject('emitter')

    function promoStatus() {
      return getStatusPromo({ startDate: '2024.03.10', endDate: '2024.04.15' })
    }
    return { emitter, promoStatus }
  },
  async asyncData({ app, $pageError }) {
    try {
      return {
        response: (await app.$api.constructor.main())?.result,
      }
    }
    catch (e) {
      new $pageError({ ...ErrorSerializer(e), message: 'Ошибка при загрузке' })
    }
  },
  data() {
    return {
      response: null,
      getPageDataByCityName: null,
      offset: 0,
      loading: false,
      advertisingActive: false,
      advertising: '',
    }
  },
  head() {
    return {
      meta: getOpenGraphMarkup({
        type: 'website',
        url: this.$nuxt.context.env.projectURL,
        title: '05.ru – интернет-магазин',
        image: this.$nuxt.context.env.projectURL + require('~/assets/images/05-avatar.png'),
        siteName: 'Интернет-магазин 05ру',
        description: 'Интернет - Магазин 05.ru предлагает вашему вниманию широчайший выбор электроники и бытовой техники. Мы гарантируем качество по самым низким ценам в Дагестане. Доставка бесплатная',
      }),
    }
  },
  computed: {
    meta() {
      return {
        isUser: !!this.$pinia.state.value.user.user,
      }
    },
    ...mapState(useLocationStore, {
      cityName: store => store.currentCity?.name?.toLowerCase() || 'махачкала',
    }),
  },
  watch: {
    cityName(newCityName) {
      if (newCityName)
        this.enable()
    },
  },
  created() {
    /**
     * Ставим категорию телефонов первой в списке
     */
    this.response.category = this.response.category.sort((a) => {
      return +a.id === 705 ? -1 : 1
    })
  },
  mounted() {
    if (this.cityName) {
      this.enable()
    }

    else {
      this.locationStore?.$onAction((mutation) => {
        if (mutation.name === 'setCurrentCity')
          this.enable()
      })
    }
  },
  methods: {
    ...mapActions(useAuthStore, { showAuthModal: 'enable' }),
    async enable() {
      /**
       * TODO заменить при переход на вью 3
       * Идиотизм с кастомной переменной для лоадинга нужен потому что
       * встроенный в пакет vue-apollo не работает, прикольно придумали
       */
      this.loading = true
      try {
        const response = await this.request()
        this.getPageDataByCityName = response.data.getPageDataByCityName
      }
      catch (e) {
        new this.$simpleError({
          message: 'Ошибка при загрузке секций конструктора',
          native: e,
        })
      }
      this.loading = false
    },
    request() {
      return this.$apollo.query({
        query: getPageDataByCityName,
        fetchPolicy: 'no-cache',
        variables: {
          name: this.cityName,
          project: 'store',
          offset: this.offset,
        },
      })
    },
    async loadMore() {
      this.loading = true
      this.offset++

      try {
        const response = await this.request()
        const data = response.data.getPageDataByCityName

        if (!data.pageInfo.hasNextPage && this.meta.isUser)
          this.$api.user.addUserAchievement({ code: 'deeper_issledovatel' })

        this.getPageDataByCityName = {
          ...data,
          items: [...this.getPageDataByCityName.items, ...data.items],
        }
      }
      catch (e) {
        new this.$simpleError({
          message: 'Ошибка при загрузке секций конструктора',
          native: e,
        })
      }

      this.loading = false
    },
    onAuthNeeded() {
      this.showAuthModal({
        subtitle: 'Войдите, чтобы получить бонусную карту, карта будет привязана к номеру телефона',
      })
    },
    onProductCartAddOrRemove(type, id) {
      this.emitter.emit(`cart-product-${type}`, {
        product: {
          id,
          quantity: 1,
        },
      })
    },
    onCategoryClick() {
      this.emitter.emit('mainpage/on-category-click')
    },
    saleSelect() {
      this.emitter.emit('mainpage/on-sale-banner-click')
    },
  },
})
